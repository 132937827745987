import React, {useEffect} from 'react'
import {Helmet} from "react-helmet"
import Nav from '../components/nav'
import Footer from '../components/footer'
import {gsap} from 'gsap'
import '../stylings/404.css'

// Assets
import {ReactComponent as BackgroundBase} from '../assets/background_colorful_v3.svg'
import {ReactComponent as BackgroundCutout} from '../assets/background_colorful_hand_v3.svg'
import {ReactComponent as Letters} from "../assets/404_letters.svg";

const NotFound = () => {
	useEffect(() => {
		let opening_home_animation = gsap.timeline({})
		opening_home_animation.from('.PageTitle', {display: "none", duration: 2, opacity: 0, delay: .5, ease: "slow(0.7, 0.7, false)"})
		opening_home_animation.from('.NavButton', {display: "none", duration: 1, opacity: 0, y: () => Math.random()*200, stagger: .25, ease: "slow(0.7, 0.7, false)"}, "-=.5")

		// Get size length of text
		// const logo = document.querySelectorAll("#team_letters path");
		//
		// for (let i=0; i<logo.length; i++) {
		// 	console.log(i + ": " + logo[i].getTotalLength());
		// }
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>404</title>
				<meta name="description" content="Invictus BCI -- Sorry we couldn't find that page... return Home!" />
			</Helmet>

			{/*Background Images*/}
			<div className={"BackgroundLayers"}>
				<BackgroundBase className={"PageBackgroundBase"}/>
				<div className={'UnknownAccentLayer AccentLayer'}/>
				<BackgroundCutout className={"PageBackgroundCutout"}/>
			</div>

			{/*Title Page*/}
			<div className={'UnknownGridLayer MainPageLayer'}>
				<Nav/>
				<div className={'PageTitle'}>
					<Letters/>
				</div>
				<Footer/>
			</div>
		</>
	)
}

export default NotFound
